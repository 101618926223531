
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as enregistrementE2Xgc6rQ5bMeta } from "/app/packages/frontend-usagers/src/pages/connexion/enregistrement.vue?macro=true";
import { default as indexSugpcCziTUMeta } from "/app/packages/frontend-usagers/src/pages/connexion/index.vue?macro=true";
import { default as mot_45de_45passe_45oublie0104MfHsq0Meta } from "/app/packages/frontend-usagers/src/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as reset_45mot_45de_45passeAtVSUUcxq8Meta } from "/app/packages/frontend-usagers/src/pages/connexion/reset-mot-de-passe.vue?macro=true";
import { default as validationKApJUTWcQFMeta } from "/app/packages/frontend-usagers/src/pages/connexion/validation.vue?macro=true";
import { default as _91_91declarationId_93_93NuLkPBh9IsMeta } from "/app/packages/frontend-usagers/src/pages/demande-sejour/[[declarationId]].vue?macro=true";
import { default as listeZF8JLgqUdGMeta } from "/app/packages/frontend-usagers/src/pages/demande-sejour/liste.vue?macro=true";
import { default as _91_91eigId_93_938zNCcjuzliMeta } from "/app/packages/frontend-usagers/src/pages/eig/[[eigId]].vue?macro=true";
import { default as listezkVEZnd8q1Meta } from "/app/packages/frontend-usagers/src/pages/eig/liste.vue?macro=true";
import { default as accessibiliteGAuuNlB0OpMeta } from "/app/packages/frontend-usagers/src/pages/footer/accessibilite.vue?macro=true";
import { default as donnees_45personnellesZzFTwzfgAqMeta } from "/app/packages/frontend-usagers/src/pages/footer/donnees-personnelles.vue?macro=true";
import { default as gestion_45cookies3uNfiD2z9qMeta } from "/app/packages/frontend-usagers/src/pages/footer/gestion-cookies.vue?macro=true";
import { default as mentions_45legalesmTcO2DT61dMeta } from "/app/packages/frontend-usagers/src/pages/footer/mentions-legales.vue?macro=true";
import { default as _91_91hebergementId_93_93LGEAMbctRoMeta } from "/app/packages/frontend-usagers/src/pages/hebergements/[[hebergementId]].vue?macro=true";
import { default as liste7Le5t75KEZMeta } from "/app/packages/frontend-usagers/src/pages/hebergements/liste.vue?macro=true";
import { default as index7OgtSB7TtUMeta } from "/app/packages/frontend-usagers/src/pages/index.vue?macro=true";
import { default as index2djzcTkLiEMeta } from "/app/packages/frontend-usagers/src/pages/messagerie/index.vue?macro=true";
import { default as mon_45comptepTpYfdIdXqMeta } from "/app/packages/frontend-usagers/src/pages/mon-compte.vue?macro=true";
import { default as _91_91organismeId_93_93vvvqvKRyZDMeta } from "/app/packages/frontend-usagers/src/pages/organisme/[[organismeId]].vue?macro=true";
export default [
  {
    name: "connexion-enregistrement",
    path: "/connexion/enregistrement",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/enregistrement.vue")
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/index.vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "/connexion/mot-de-passe-oublie",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/mot-de-passe-oublie.vue")
  },
  {
    name: "connexion-reset-mot-de-passe",
    path: "/connexion/reset-mot-de-passe",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/reset-mot-de-passe.vue")
  },
  {
    name: "connexion-validation",
    path: "/connexion/validation",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/validation.vue")
  },
  {
    name: "demande-sejour-declarationId",
    path: "/demande-sejour/:declarationId?",
    meta: _91_91declarationId_93_93NuLkPBh9IsMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/demande-sejour/[[declarationId]].vue")
  },
  {
    name: "demande-sejour-liste",
    path: "/demande-sejour/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/demande-sejour/liste.vue")
  },
  {
    name: "eig-eigId",
    path: "/eig/:eigId?",
    meta: _91_91eigId_93_938zNCcjuzliMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/eig/[[eigId]].vue")
  },
  {
    name: "eig-liste",
    path: "/eig/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/eig/liste.vue")
  },
  {
    name: "footer-accessibilite",
    path: "/footer/accessibilite",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/accessibilite.vue")
  },
  {
    name: "footer-donnees-personnelles",
    path: "/footer/donnees-personnelles",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/donnees-personnelles.vue")
  },
  {
    name: "footer-gestion-cookies",
    path: "/footer/gestion-cookies",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/gestion-cookies.vue")
  },
  {
    name: "footer-mentions-legales",
    path: "/footer/mentions-legales",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/mentions-legales.vue")
  },
  {
    name: "hebergements-hebergementId",
    path: "/hebergements/:hebergementId?",
    meta: _91_91hebergementId_93_93LGEAMbctRoMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/hebergements/[[hebergementId]].vue")
  },
  {
    name: "hebergements-liste",
    path: "/hebergements/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/hebergements/liste.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7OgtSB7TtUMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/index.vue")
  },
  {
    name: "messagerie",
    path: "/messagerie",
    meta: index2djzcTkLiEMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/messagerie/index.vue")
  },
  {
    name: "mon-compte",
    path: "/mon-compte",
    meta: mon_45comptepTpYfdIdXqMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/mon-compte.vue")
  },
  {
    name: "organisme-organismeId",
    path: "/organisme/:organismeId?",
    meta: _91_91organismeId_93_93vvvqvKRyZDMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/organisme/[[organismeId]].vue")
  }
]